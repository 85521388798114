@import "./styles/dashboard.css";
@import "./styles/login.css";
/* google font */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap");
@import "./styles/manageDataStyles.css";
@import "./styles/menu.css";
@import "./styles/DataTableStyles.css";
@import "./styles/Dropdown.css";
@import "./styles/navbar.css";
@import "./styles/s116Input.css";
@import "./styles/landing.css";
@import "./styles/deploy.css";

/* layout */
:root {
  --primary: #1aac83;
  --error: #e7195a;
}
body {
  background: #272d31;
  margin: 0;
  font-family: "Montserrat";
}
.navbar-landing {
  background: #202427;
  color: #fff;
  font-family: "Montserrat";
  text-decoration: none;
  text-align: center;
  margin: 0 auto;
  padding: 10px 2px;
  padding-left: 9%;
}
.navbarA-landing {
  background: #202427;
  color: #fff;
  font-family: "Montserrat";
  text-decoration: none;
  text-align: center;
  margin: 0 auto;
  padding: 10px 2px;
  padding-left: 20%;
}
.navbarAuth-landing {
  background: #202427;
  color: #fff;
  font-family: "Montserrat";
  text-decoration: none;
  margin: 0 auto;
  padding: 10px 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.navbarAuth-landing h1 {
  margin: 0; /* Ensure no margin around the heading */
}

.flex-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.left-content {
  flex-grow: 1;
}

.right-content {
  margin-right: 3%; /* Adjust as needed */
}
header {
  background: #202427;
}
/* .title{
  color:#fff;
  font-size:xx-large;
} */

.pages {
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}
.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttons {
  display: flex;
  gap: 1rem;
}

.documentation-button {
  padding: 0.5rem 1rem;
  border: 1px solid white;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.documentation-button:hover {
  background-color: white;
  color: darkblue;
}

.navbar-container {
  display: flex;
  justify-content: space-evenly; /* Center horizontally */
  align-items: center; /* Center vertically */
  max-height: 100%; /* Ensure the container takes at least the viewport height */
  max-width: 100%;
}
/* ******************************************************** */
/* FORM STYLES */
.create-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0%;
}

.form-container {
  width: 48%;
  padding-bottom: 4rem;
}

.create {
  color: white;
}

.create input {
  border-radius: 10px;
  margin-bottom: 2rem;
  padding: 2%;
  color:  rgb(64, 63, 63)

}

.create select {
  padding: 2%;
  width: 100%;
  border-radius: 10px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  font-family: "Montserrat";
  font-size: 14px;
  color: rgb(64, 63, 63)
}

.placeholder-color {
  color: rgb(64, 63, 63)
}


.create label {
  font-size: 18px;

}

.create h3 {
  font-size: 42px;
  text-align: center;
}

.create p {
  font-size: 16px;
  text-align: center;
}

.create button {
  color: white; /* Text color */
  border: none;
  border-radius: 10px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  margin-top: 10px; /* Margin on the top */
  cursor: pointer;
}
.create button:hover {
  background-color: #00994D; /* Darker shade of green on hover */
}
/* .input {
  background-color: green;
} */

/* ******************************************************** */
/* homepage */
.home {
  display: grid;
  background: #272d31;
  grid-template-columns: 3fr 1fr;
  gap: 100px;
}

.company-box {
  background: #2c536c;
  border-radius: 4px;
}
.company-box :hover {
  background: #617e91;
  border-radius: 4px;
}

.company-details {
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  display: block;
  text-decoration: none;
}

.company-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: #fff;
}
.company-details p {
  margin: 0;
  font-size: 0.9em;
  color: #fff;
}
.company-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #ec6a5f;
  font-size: 24px;
  padding: 6px;
  border-radius: 20%;
  color: #fff;
}

.company-details span:hover {
  background: #f34839;
}

/* delete button */
/* .delete-button{
  background: #e7195a !important;
} */

/* new workout form */
label,
input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 14px;
}

.input-dropdown {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 14px;
}
form button {
  background: #62c554;
  border: 0;
  color: #fff;
  padding: 10px;
  font-family: "Montserrat";
  font-size: medium;
  border-radius: 4px;
  cursor: pointer;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin-top: 2rem;
}
input.error {
  border: 2px solid var(--error);
}
select.error {
  border: 2px solid var(--error);
}

/* .button {
  padding: 10px;
  background-color: #e9e9e9;
  border-radius: 10px;
  border:none;
  text-align: center;
  margin-right: 80px;
  margin-left:80px;
  width: 45%;
  height: 500px;
  font-family: "Montserrat";
  font-weight:bolder;
  font-size:xx-large;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #1A3A50;
  color: #fff;
}
.menu{
  display: flex;
  justify-content: center;
}
.manage-data{
  color:#fff;
} */

/* .button-secondary {
  background-color: #1A3A50;
  color:#fff;
}

.button-secondary:hover {
  background-color: #ff7f6e;
} */

/*
.button {
  padding: 10px;
  background-color: #e9e9e9;
  border-radius: 10px;
  border:none;
  text-align: center;
  width: 45%;
  height: 500px;
  font-family: "Montserrat";
  font-weight:bolder;
  font-size:xx-large;
}


.selected {
  background-color: #1A3A50;
  color: #ffffff;
} */
