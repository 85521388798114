/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

.some-test {
  color: white;
}
.dashboard-container {
  margin-bottom: 4%;
  margin-top: 4%;
  margin-left: 2%;
  margin-right: 2%;
  background: #151c20 !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.esg-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.button-general {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  padding: 10px;
  background-color: #fff;
  color: #1A3A50;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border:none;
  text-align: center;
  width: 25%;
  height: 70px;
  font-family: "Montserrat";
  font-weight:bolder;
  font-size:large;
  transition: background-color 0.3s ease;
}

.button-environment {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  padding: 10px;
  background-color: #fff;
  color: #1A3A50;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border:none;
  text-align: center;
  width: 25%;
  height: 70px;
  font-family: "Montserrat";
  font-weight:bolder;
  font-size:large;
  transition: background-color 0.3s ease;
}

.button-social {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  padding: 10px;
  background-color: #fff;
  color: #1A3A50;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  border:none;
  width: 25%;
  height: 70px;
  font-family: "Montserrat";
  font-weight:bolder;
  font-size:large;
  transition: background-color 0.3s ease;

}

.button-governance {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: pre-line;
  padding: 10px;
  background-color: #fff;
  color: #1A3A50;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border:none;
  text-align: center;
  width: 25%;
  height: 70px;
  font-family: "Montserrat";
  font-weight:bolder;
  font-size:large;
  transition: background-color 0.3s ease;
}

.not-selected {
  background-color: #1A3A50;
  color:#fff;
}

.drop-down-container {

  margin-top: 4%;
  margin-bottom: 4%;
  margin-left:25%;
  margin-right: 25%;
}

.graph-container {
  background: #151c20 !important;
  height: 50%;
  border-radius: 2%;
  padding-top: 40px;
  padding-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.graph-headline {
  font-size: 50px;
  color: #d3d3d3;
  text-align: center;
}
.graph {
  width: 600px !important;
  height: 400px !important;
  background: #151c20 !important;
  margin-left: 40px;
  /* margin-top: 10px; */
  border-radius: 2%;
  font-family: "Montserrat";

}


.line {
  stroke: steelblue;
  stroke-width: 1;
  fill: none;
}

.x-axis .tick line {
  display: none;
}

.x-axis .tick text {
  font-size: 12px;
  color: #767373
}

.grid-line {
  stroke: #767373;
  stroke-width: .08;
}

.y-axis {
  color: #c3c0c0;
  stroke-width: .1;
}

.y-axis .tick text {
  font-size: 12px; /* Adjust the font size of the axis values */
  color: #767373;
  /* display: none; */

}


.horizontal-line {
  stroke: #767373;
  stroke-width: .5;
}

.horizontal-grid-line {
  stroke: #767373;
  stroke-width: .08;
}


rect {
  pointer-events: all;
  fill-opacity: 0;
  stroke-opacity: 0;
  z-index: 1;
}

.tooltip {
  position: absolute;
  padding: 10px;
  background-color: #62C554;
  color: white;
  border: 0.5px solid white;
  border-radius: 10px;
  display: none;
  opacity: 0.48;
  font-size: 12px;
}

.graph-social {
  margin-bottom: 40px;
  padding-bottom: 40px;
}

/* ************************************************************************* */

.data-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  text-align: center;
  white-space: pre-line;
  margin-right: 6em;
  width: 25%;
  /* margin-bottom: 3.5em; */
  /* padding-right: 4%; */
}

.goto-methodology {
  width: 90%;
  height: 70px;
  padding-right: 4%;
  margin-top: 10%;
  border-radius: 10px;
  text-align: center;
  font-family: "Montserrat";
  font-size: large;
  background-color: #4682B4; /* Blue background */
  color: #fff;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

.goto-methodology:hover {
  background-color: #87CEEB; /* Lighter blue on hover */
}

.goto {
  width: 90%;
  height: 70px;
  padding-right: 4%;
  margin-top: 10%;
  border-radius: 10px;
  text-align: center;
  font-family: "Montserrat";
  font-size: large;
  background-color: #4CAF50; /* Green background */
  color: #fff;
  border: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Changes cursor to pointer to indicate it's clickable */
  transition: background-color 0.3s ease; /* Smooth transition for background color change */
}

.goto:hover {
  background-color: #45a049; /* Darker green on hover */
}

.meth-tooltip-S116 {
  position: absolute;
  top: 48%;
  /* left: 50%; */
  /* transform: translateX(50%); */
  width: 44%;
  height: 14%;
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
  text-align: center;
  font-size: 14px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  /* max-width: 300px; Adjust the maximum width as needed */
  /* overflow: hidden; */
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 10px;
}

.math-formula {
  width: 100%; /* Ensure the formula container takes up the available width */
  display: flex;
  justify-content: center;
  align-items: center; /* Add some padding for spacing */
}

.goto-methodology:hover .meth-tooltip-S116 {
  opacity: 0.84;
  pointer-events: auto;
}

.formula {
  font-size: 10px;
}

.formula p {
  font-size: 18px;
  margin-bottom: 4%;
}

.export {
  margin-top: 18%;
  font-size: 20px;
  color: #fff;
}

.file-icons {
  margin-top: 8%;
  display: flex;
  width: 42%;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 1090px) {

  .meth-tooltip-S116 {
    /* Adjust the tooltip's position for smaller screens */
    left: 50%;
    top: auto;
    bottom: 10%;
    width: 62%;
    height: 14%;
    position: absolute;
    transform: translateX(-50%);
    /* ... other adjustments ... */
  }

}

@media (max-width: 950px) {
  /* Adjustments for screens smaller than 768px */
  .dashboard-container {
    margin: 2% auto;
    border-radius: 10px;
  }

  .esg-buttons {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    border-radius: 10px;
  }

  .button-general,
  .button-environment,
  .button-social,
  .button-governance {
    width: 100%;
    height: 60px;
    font-size: medium;
    margin-bottom: 10px;
    border-radius: 10px;
  }

  .graph-container {
    flex-direction: column;
    height: auto;
    padding-top: 20px;
    padding-bottom: 40px;
  }

  .graph {
    width: 100% !important;
    height: 300px !important;
    margin-left: 0;
    margin-bottom: 10%;
  }

  .data-buttons {
    width: 100%;
    margin-right: 0;
    margin-top: auto;
    padding-right: 0;
    align-items: center;
  }

  .goto-methodology {

    width: 90%;
    height: 70px;
    padding-right: 4%;
    margin-top: 2%;
    border-radius: 10px;
    text-align: center;
    font-family: "Montserrat";
    /* font-weight:bolder; */
    font-size:large;
    background-color: #62C554;
    color:#fff;
    border:none;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;

  }

  .goto {

    width: 90%;
    height: 70px;
    padding-right: 4%;
    margin-top: 2%;
    border-radius: 10px;
    text-align: center;
    font-family: "Montserrat";
    /* font-weight:bolder; */
    font-size:large;

    background-color: #1A3A50;
    color:#fff;
    border:none;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* .meth-tooltip-S116 {
    width: 80%;
    height: auto;
    top: 60%;
    padding: 8px;
  } */

  .export {
    margin-top: 5%;
  }

  /* .icon-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

  } */

  .file-icons {
    width: 80%;
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

  }

  .meth-tooltip-S116 {
    /* Adjust the tooltip's position for smaller screens */
    left: 50%;
    top: auto;
    bottom: 10%;
    width: 68%;
    height: 14%;
    position: absolute;
    transform: translateX(-50%);
    /* ... other adjustments ... */
  }

  .math-formula {
    width: 100%; /* Ensure the formula container takes up the available width */
    display: flex;
    justify-content: center;
    align-items: center; /* Add some padding for spacing */
  }
}

@media (max-width: 680px) {
  .meth-tooltip-S116 {
    position: absolute;
    /* Further adjustments for even smaller screens */
    left: 50%;
    bottom: 20%;
    width: 44%;
    height: 14%;
    transform: translateX(-50%);
    /* ... other adjustments ... */
  }
  .math-formula {
    width: 100%; /* Ensure the formula container takes up the available width */
    display: flex;
    justify-content: center;
    align-items: center; /* Add some padding for spacing */
  }

  .meth-tooltip-S116 {
    /* Adjust the tooltip's position for smaller screens */
    left: 50%;
    top: auto;
    bottom: 10%;
    width: 88%;
    height: 14%;
    position: absolute;
    transform: translateX(-50%);
    /* ... other adjustments ... */
  }
}
