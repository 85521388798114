/* Updated styles for the dropdown */
.select-dropdown {
  font-size: 16px;
  padding: 12px;
  border-radius: 8px;
  width: 20%;
  appearance: none;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="8"><path fill="%23333" d="M12 0H0L6 8z"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px 8px;
  padding-right: 30px;
}

/* DROPDOWN EDIT */
.dropdown-container {
  display: flex;
  justify-content: flex-end; /* Align contents to the right side */
  
}

.dropdown-edit {
  position: relative;
}

.custom-select {
  border-radius: 20px;
  padding: 6% 6% 6% 6%; /* Added padding for the icon */
  font-family: "Montserrat";
  font-size: 14px;
  appearance: none;
  background-size: 2rem;
  text-align: center;
  width: 8rem; /* Adjust width as needed */
  background-color: #ffffff; /* Set background color */
  /* Add padding to the right for the icon */
}

.custom-select option {

  text-align: center;

}

/* HEADER */
.dropdown {
  position: relative;
}
.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  display: none;
  padding-left: 2rem;
  padding-right: 2rem;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

/* Updated styles for the dropdown options */
.dropdown-menu a,
.dropdown-menu div {
  display: block;
  padding: 12px;
  text-decoration: none;
  color: #333;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

/* Style for hovering over dropdown options */
.dropdown-menu a:hover,
.dropdown-menu div:hover {
  background-color: #f0f0f0;
}
