/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;700&display=swap');

.button-manage-data {
    padding: 10px;
    background-color: #e9e9e9;
    border-radius:10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border:none;

    text-align: center;
    width: 50%; /* Updated */
    height: 600px;
    font-family: "Montserrat";
    font-weight: bolder;
    font-size: xx-large;
    transition: background-color 0.3s ease;
  }

  .button-dashboard {
    padding: 10px;
    background-color: #e9e9e9;
    border-radius:10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border: none;
    border-left: 1px solid;
    border-color: #99A1A7;
    text-align: center;
    width: 50%; /* Updated */
    height: 600px;
    font-family: "Montserrat";
    font-weight: bolder;
    font-size: xx-large;
    transition: background-color 0.3s ease;
  }
  
  .button-manage-data:hover {
    background-color: #1A3A50;
    color: #fff;
  }
  .button-dashboard:hover {
    background-color: #1A3A50;
    color: #fff;
  }
  .menu-menu{
    display: flex;
    justify-content: space-between;
    margin-top: 2%;
  }