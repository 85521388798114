.table {
  width: 100%;
  border-collapse: separate; /* Changed from 'collapse' to 'separate' */
  margin-top: 3%;
  border-spacing: 0; /* Added to remove white lines */
  border-radius: 10px; /* Added to make the table more rounded */
  overflow: hidden; /* Added to hide any content that goes beyond the rounded corners */
  text-align: center; /* Added to center align the content */

}

.table th {
  background-color: #679D54;
  color: white;
  padding: 10px;
  border: none; /* Added to remove cell borders */
}

.table td {
  padding: 10px;
  border: none; /* Added to remove cell borders */
  color: white;

}

.table-dropdown {
  background: white;
  border: none;
  border-radius: 20px;
  text-align: center;
}

/* For input elements */

.table td input {
  background: white;
  border: none;
  border-radius: 20px;
  text-align: center;
  width: 100%;
}

.input-element {
  padding: 10px;
  background: white;
  border: none;
  border-radius: 20px;
  text-align: center;
  padding: 10px; /* Adjust padding as needed */
  width: 100%; /* Make the elements take up the full width */
  box-sizing: border-box; /* Include padding and border in the width */
  font-family: "Montserrat";
  font-size: 14px;
  margin: 0; /* Remove any default margins */
}

.input-dropdown-table {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  border-radius: 20px;
  text-align: center;
  width: 100%;
  border: 1px solid #ddd;
  box-sizing: border-box;
  font-family: "Montserrat";
  font-size: 14px;
}

.table button {
  margin-right: 5px;
}

.save-bttn {
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #62C554;
  font-size: 24px;
  padding: 6px;
  border: none;
  border-radius: 20%;
  color: #fff;

}

.delete-bttn {
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #ec6a5f;
  font-size: 24px;
  padding: 6px;
  border: none;
  border-radius: 20%;
  color: #fff;
}

.delete-bttn:hover {
  background: #f34839;
}

.edit-bttn {
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f4bf4f;
  font-size: 24px;
  padding: 6px;
  border: none;
  border-radius: 20%;
  color: #fff;
}

.edit-bttn:hover {
  background: #ffaa00;
}
