.wallet-connect{
  background-color: #87CEEB; /* Baby blue color */
  color: white; /* Text color */
  font-size:16px;
  border: none;
  border-radius: 10px; /* Rounded corners */
  padding: 10px 20px; /* Padding */
  margin-top: 10px; /* Margin on the top */
  cursor: pointer;
}

.wallet-connect:hover {
  background-color: #5CADCB; /* Lighter shade on hover */
}

.blockchain-title{
  color: white;
}