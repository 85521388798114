
.manage-data{
    margin-top: 3%;
    margin-left: 20%;
    margin-right:20%;
}

.selection-menu{
  padding-bottom:4%;
}

.button-register-data {
    padding: 10px;
    background-color: #fff;
    color: #1A3A50;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border:none;
    text-align: center;
    width: 50%;
    height: 70px;
    font-family: "Montserrat";
    font-weight:bolder;
    font-size:large;
    transition: background-color 0.3s ease;
  }
  .button-edit-data {
    padding: 10px;
    background-color: #fff;
    color: #1A3A50;
    border-radius: 10px;
    border:none;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-bottom-left-radius:0px;
    text-align: center;
    width: 50%;
    height: 70px;
    font-family: "Montserrat";
    font-weight:bolder;
    font-size:large;
    transition: background-color 0.3s ease;
  }

  .selected-manage-data{
    background-color: #1A3A50;
    color:#fff;
  }