.landing-container {
  display: flex;
  justify-content: space-evenly; /* Center horizontally */
  align-items: center; /* Vertically align items */
  
}

.welcome-text {
  margin-top:5%;
  padding: 4rem;
  padding-right: 8rem;
}

.welcome-text h1 {
  font-size: 50px;
  color: white;
}

.welcome-text p {
  font-size: 24px;
  color: white;
}

.signup-landing {
  margin-top: 4rem;
  display: flex;
  justify-content: space-between;
  align-items: center; /* Vertically align items */
}

.signup-landing input,
.signup-button {
  border: none;
  display: inline-block;
  vertical-align: middle;
  box-sizing: border-box;
}

.signup-landing input {
  margin-top: 1.5rem;
  width: 60%;
  height: 60px; /* Adjust the height as needed */
  border-radius: 20px;
  padding: 1rem;
}

.signup-button {

  width: 30%;
  height: 60px; /* Adjust the height to match the input */
  display: inline-block; /* Add this display property */
  vertical-align: middle; /* Add this vertical-align property */
}

.signup-landing button {
  border-radius: 20px; /* Add border-radius here */
  background: #62C554;
  color: #fff;
  font-family: "Montserrat";
  font-size: medium;
  cursor: pointer;
  padding: 1rem;
  text-align: center;
  border: none;
  width: 100%;
  height: 100%; /* Use 100% height to match parent (.signup-button) */
}
