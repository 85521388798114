.form-container {

  width: 48%;

}

.login-input {
  height: 3.5rem;
  font-size: 16px;
  border-radius: 20px;
  padding: 4%;
  margin-top: 8%;
}

.login-btn {
  margin-top: 8%;
  background: #62C554;
  border: 0;
  color: #fff;
  border-radius: 20px;
  padding: 2% 8% 2% 8%;
  font-family: "Montserrat";
  font-size: medium;
  cursor: pointer;
}

.login-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0%;

}

.login-header {
  text-align: center;
}

.login-header h3 {
  font-size: 38px;
}
