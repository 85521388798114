header .container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 16rem;

}
header .container-auth {
  max-width: 1400px;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
}
header a {
  color: #FFF;
  font-family: "Montserrat";
  text-decoration: none;
}

header .buttons {
  cursor: pointer;
  width: 20%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  color: white;
}

header .company-navbar-container {

  display: flex;
  align-items: center;
  justify-content: space-between;

}

header .company-navbar-right {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding-right: 3rem;
  gap: 1rem; /* Add space between items */
}


header .company-navbar-left {
  padding-left: 46%;
  align-items: center;
}
.btn {
  border: 1px solid white;
  background: transparent;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  text-align: center;
  transition: background 0.3s, color 0.3s, border 0.3s;
  border-radius: 10px;
}

.btn:hover {
  background: white;
  color: #001f3f; /* Very dark blue */
  border: none;
}

.go-to-account {
  display: inline-block;
}
.navbar-landing-copmany .btn {
  margin-right: 15px; /* Add some space to the right of each button */
}

.navbar-landing-copmany .btn:last-child {
  margin-right: 0; /* Remove the margin from the last button */
}

.navbar-company {
  background: #202427;
  color: #FFF;
  font-family: "Montserrat";
  text-decoration: none;
  text-align: center;
  margin: 0 auto;
  padding: 10px 2px;

}

/* Sidebar.css */
/* Sidebar.css */
.sidebar {
  position: fixed;
  top: 0;
  right: -100%; /* Start offscreen on the right */
  width: 70%; /* Adjust width as needed */
  max-width: 300px; /* Maximum width */
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0px 4px rgba(0, 0, 0, 0.2); /* Shadow on the left */
  transition: right 0.3s ease-in-out;
  z-index: 1000; /* Ensure the sidebar appears above other content */
  display: flex;
  flex-direction: column;
}

.sidebar.open {
  right: 0; /* Slide in from the right when open */
}

.sidebar-content {
  flex-grow: 1; /* Allow content to expand and push footer */
  padding: 20px;
  overflow-y: auto; /* Add scrollbar if content overflows */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sidebar {
    width: 100%; /* Full width on smaller screens */
    max-width: 100%; /* No maximum width on smaller screens */
    box-shadow: none; /* No shadow on smaller screens */
  }
}

